<template>
  <div class="entity-setting-form-desc-container">
    <div class="elv-apply-project-form-item-desc help">
      <div class="elv-apply-project-form-item-desc__icon"><SvgIcon name="help" width="12" height="12" /></div>
      <i18n-t :keypath="currentEntitySettingDesc" tag="p" scope="global">
        <template v-if="props.descType === 'accountingStartDate'" #handler>
          <span class="elv-apply-project-form-item-desc-handler" @click="linkToAccountingStartDate">{{
            transformI18n('desc.learnChooseAccountingDate')
          }}</span>
        </template>
      </i18n-t>
    </div>

    <div
      v-if="props.model !== 'edit' && props.descType !== 'preferredCurrencyUnit'"
      class="elv-apply-project-form-item-desc waring"
    >
      <div class="elv-apply-project-form-item-desc__icon">
        <SvgIcon name="warning-emptyBg" width="12" height="12" />
      </div>
      {{ transformI18n('common.cannotBeChanged') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { transformI18n } from '@/i18n'

const { locale } = useI18n()

type DescType =
  | 'timeZone'
  | 'gstSetting'
  | 'accountingStartDate'
  | 'functionalCurrency'
  | 'preferredCurrencyUnit'
  | 'financialSnapshot'

const props = defineProps<{
  model: String
  descType: DescType
}>()

const entitySettingFormDescType: Record<DescType, string> = {
  timeZone: 'desc.entityTimeZoneTipInfo',
  gstSetting: 'desc.entityGSTSettingTipInfo',
  accountingStartDate: 'desc.entityAccountingStartDateTipInfo',
  functionalCurrency: 'desc.entityFunctionalCurrencyTipInfo',
  preferredCurrencyUnit: 'desc.preferredCurrencyUnitTipInfo',
  financialSnapshot: ''
}

const currentEntitySettingDesc = computed(() => {
  return entitySettingFormDescType[props.descType] || ''
})

const linkToAccountingStartDate = () => {
  const linkUrl =
    locale.value === 'en'
      ? 'https://docs.elven.com/english/english-1/system-configuration/project-and-entities/choose-an-accounting-start-date'
      : 'https://docs.elven.com/english/settings/xiang-mu-he-zhu-ti/xuan-ze-kuai-ji-qi-shi-ri-qi'
  window.open(linkUrl, '_blank')
}
</script>

<style lang="scss" scoped>
.entity-setting-form-desc-container {
  padding-top: 4px;

  .elv-apply-project-form-item-desc {
    display: flex;
    align-items: flex-start;
    margin-top: 4px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .elv-apply-project-form-item-desc__icon {
      display: flex;
      align-items: center;
    }

    .elv-apply-project-form-item-desc-handler {
      text-decoration: underline;
      cursor: pointer;
    }

    div {
      width: 12px;
      margin-right: 4px;
    }

    svg {
      margin-top: 1px;
    }

    &.help {
      color: #636b75;

      svg {
        fill: #838d95;
        margin-top: 2px;
      }
    }

    &.waring {
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      align-items: center;
      color: #b06921;

      svg {
        fill: #b06921;
        margin-top: 2px;
      }
    }
  }
}
</style>
